import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WipConfigService {

  constructor(private http: HttpClient) {    
  }

  private headers = () =>  new HttpHeaders({
    'X-TenantID': sessionStorage.getItem("powerwip-companyIdentifier")
  })

  getAllWipConfig(id) {
    return this.http.get<any>(environment.baseAPIUrl + '/api/wipConfiguration', { headers: this.headers() })
  }
  getWipConfig(data) {
    console.log('servicedata', data.id)
    //console.log(this.)
    return this.http.get<any>(environment.baseAPIUrl + `/api/wipConfiguration/${data.key}`, { headers: this.headers() })
  }
  addWipConfig(data) {
    // this.headers.set('X-TenantID', data.id)
    return this.http.post<any>(environment.baseAPIUrl + '/api/wipConfiguration', data, { headers: this.headers() })
  }
  delWipConfig(data) {
    return this.http.delete<any>(environment.baseAPIUrl + `/api/wipConfiguration/${data.key}`, { headers: this.headers() })
  }
  updateWipConfig(data) {
    return this.http.put<any>(environment.baseAPIUrl + `/api/wipConfiguration/${data.key}`, data.wipData, { headers: this.headers() })
  }
  bulkCreateWipConfig(data) {
    return this.http.post<any>(environment.baseAPIUrl + `/api/wipConfiguration/bulk`, data, { headers: this.headers() })
  }
}
